// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import Chart from 'chart.js/auto';
import { Scatter } from 'react-chartjs-2';

const { AppBar, Accordion, AccordionSummary, AccordionDetails, Grid, FormControl, ThemeProvider, Switch, RadioGroup, Radio, FormControlLabel, InputLabel, IconButton, Popover, Select, MenuItem, Container, TextField, Box, Stack, Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Button, Typography, Card, CardContent, Badge, Toolbar, InputAdornment, useMediaQuery, Divider, Tabs, Tab, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } = require("@mui/material");
const csv = require('csvtojson');

let theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg",
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined"
      }
    }
  },
});
theme = responsiveFontSizes(theme);
Chart.defaults.font.family = theme.typography.fontFamily;

const COLORS = ['rgba(25, 118, 210, 0.7)', 'rgba(156, 39, 176, 0.7)', 'rgba(46, 125, 50, 0.7)', 'rgba(237, 108, 2, 0.7)'];
const HIGHLIGHT = '#888888';
const DRUG_TYPES = ["Ice", "Heroin/Cocaine", "Ecstasy/Ketamine", "Cannabis_resin"];

// function types(drugs) {
//   return [...new Set(drugs.map(drug => drug.type))];
// }

function filteredGuidelines(guidelines, type) {
  return guidelines.filter(row => row.type === type);
}

function bandId(filtered, g) {
  if (!g || g < filtered[0].minG) {
    return 0;
  }
  for (let i = 0; i < filtered.length; i++) {
    const e = filtered[i];
    if (g >= e.minG && g <= e.maxG) {
      return i;
    }
  }
  return -1; //filtered.length - 1
}

function bandIdFromYr(filtered, yr) {
  if (!yr || yr < filtered[0].minYr) {
    return 0;
  }
  for (let i = 0; i < filtered.length; i++) {
    const e = filtered[i];
    if (yr > e.minYr && yr <= e.maxYr) {
      return i;
    }
  }
  return filtered.length - 1;
}

function oneThirdToStr(yr) {
  if (yr === 1.333333333) {
    return '1⅓';
  }
  return yr;
}

function isError(str) {
  return isNaN(str) || isNaN(parseFloat(str));
}

function GuidelinesTable(props) {
  if (!props.guidelines) {
    return;
  }
  const cellStyle = {whiteSpace: 'nowrap'};
  const filtered = filteredGuidelines(props.guidelines, props.type);
  const id = bandId(filtered, props.g);
  return (
    <TableContainer>
      <Table size="small" >
        <TableHead>
          <TableRow >
            {/* <TableCell>Type</TableCell> */}
            <TableCell style={cellStyle} align="right">Grammes</TableCell>
            <TableCell style={cellStyle} align="right">Years</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filtered.map(row => {
            const minYr = oneThirdToStr(row.minYr);
            const maxYr = oneThirdToStr(row.maxYr);
            return (
              <TableRow
                key={row.type + row.minG}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ['&:nth-of-type(' + (id + 1) + ')']: { backgroundColor: HIGHLIGHT, color: 'white' }
                }}
                // rgb(240, 247, 255)
              >
                {/* <TableCell>{row.type}</TableCell> */}
                <TableCell style={cellStyle} align="right" sx={{color: 'inherit'}}>{row.minG} - {row.maxG}</TableCell>
                <TableCell style={cellStyle} align="right" sx={{color: 'inherit'}}>{minYr} - {maxYr}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function values2float(guidelines) {
  return guidelines.map(obj => ({
    type: obj.type,
    minG: +obj.minG,
    maxG: +obj.maxG,
    minYr: +obj.minYr,
    maxYr: +obj.maxYr
  }));
}

function plural(no, str) {
  if (isNaN(no)) return "N/A";
  return no + " " + str + (no>1 ? "s" : "");
}

function months2text(months) {
  if (isNaN(months)) {
    return "N/A";
  }
  if (!Math.floor(months)) {
    return "0 year";
  }
  const yr = Math.floor(months / 12);
  const modMo = Math.floor(months % 12);
  let text = "";
  if (yr) {
    text += plural(yr, "year") + " ";
  }
  if (modMo) {
    text += plural(modMo, "month");
  }
  return text;
}

function roundOff(no) {
  return Math.round(no * 100)/100;
}

function display(no) {
  if (isNaN(no)) {
    return "N/A";
  }
  return no.toFixed(8).replace(/0+$/, '').replace(/\.$/, '');
}

function y(x1, x2, y1, y2, x, isRound) {
  const fraction = (x - x1)/(x2 - x1);
  const isRounded = display(fraction) !== roundOff(fraction);
  let result = fraction;
  if (isRound) {
    result = roundOff(result);
  }
  result = result * (y2-y1) + y1;
  return { fraction, isRounded, result };
}

function sentence(type, g, guidelines, isRound) {
  if (!guidelines || !g) {
    return {text: 'N/A'};
  }
  const filtered = filteredGuidelines(guidelines, type);
  const first = filtered[0];
  if (g < first.minG) {
    return {text: '< ' + first.minYr + ' years'};
  }
  const last = filtered[filtered.length - 1];
  if (g > last.maxG) {
    return {text: '> ' + last.maxYr + ' years'};
  }
  const band = filtered[bandId(filtered, g)];
  const { minG, maxG, minYr, maxYr } = band;
  const minMo = minYr * 12;
  const maxMo = maxYr * 12;
  const { fraction, isRounded, result } = y(minG, maxG, minMo, maxMo, g, isRound);
  const months = result;
  const monthsFinal = isRound ? Math.floor(months) : months;
  const text = months2text(months);
  return { type, g, band, minMo, maxMo, fraction, isRounded, months, monthsFinal, text };
}


function months2g(months, type, guidelines, isRound) {
  if (!guidelines) return 0;
  const yr = months / 12;
  const filtered = filteredGuidelines(guidelines, type);
  const bandId = bandIdFromYr(filtered, yr);
  const band = filtered[bandId];
  const { minG, maxG, minYr, maxYr } = band;
  const g = y(minYr, maxYr, minG, maxG, yr, isRound).result;
  return g;
}

function Calculation(props) {
  const {output, isRound} = props;
  if (!output) return;
  const band = output.band;
  return (
    <Typography component="div">
      Sentence for {display(output.g)}g of {output.type} in band {band.minG}-{band.maxG}g, {band.minYr}-{band.maxYr} years
      <table>
        <tbody>
          <tr>
            <td rowSpan="2">=</td>
            <td style={{textAlign: 'center', borderBottom: '1px solid'}}>(g − min_g)</td>
            <td rowSpan="2">× (max_mo − min_mo) + min_mo</td>
          </tr>
          <tr>
            <td style={{textAlign: 'center'}}>(max_g − min_g)</td>
          </tr>
          <tr>
            <td rowSpan="2">=</td>
            <td style={{textAlign: 'center', borderBottom: '1px solid'}}>({display(output.g)}g − {band.minG}g)</td>
            <td rowSpan="2">× ({output.maxMo} months − {output.minMo} months) + {output.minMo} months</td>
          </tr>
          <tr>
            <td style={{textAlign: 'center'}}>({band.maxG}g − {band.minG}g)</td>
          </tr>
        </tbody>
      </table>
      <div style={{marginLeft: '3px'}}>
        = {isRound ? roundOff(output.fraction).toFixed(2) : display(output.fraction)}{isRound && output.isRounded && '*'} × {output.maxMo - output.minMo} months + {output.minMo} months <br />
        = {display(output.months)} months <br />
        = {output.text} 
        {isRound && output.isRounded && <Typography sx={{borderTop: '1px solid', mt: 2}}>
          * The actual figure is {display(output.fraction)}, but rounded to 2 dp, it is {roundOff(output.fraction).toFixed(2)}
        </Typography>}
      </div>
    </Typography>
  );
}

function BasicPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <CardActions sx={{mt: -2, ml: '3px'}}> */}
      
      <IconButton onClick={handleClick} size="small" sx={{mt: -.7}} >
          {props.info ? <InfoIcon /> : <LaunchIcon fontSize="inherit" color="primary" />}
        </IconButton>
        {/* <Button size="small" aria-describedby={id} onClick={handleClick}>
          Show math
        </Button> */}
      {/* </CardActions> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{p: 2}}>{props.children}</Box>
      </Popover>
    </div>
  );
}

function CardBasic(props) {
  return (
    <Card variant={props.dark && 'elevation'} sx={props.dark && {backgroundColor: HIGHLIGHT}} >
      <CardContent sx={{mb: -1}}>
        <Typography variant="body2" color={props.dark ? 'white' : 'text.secondary'} gutterBottom>
          {props.title}
        </Typography>
        <Typography color={props.dark && 'white'} variant="h5" >{props.text}</Typography>
      </CardContent>
      {props.children}
    </Card>
  )
}

function Drug(props) {
  // const [type, setType] = useState('Heroin/Cocaine');
  const [gStr, setGStr] = useState('1');
  const {index, type, onTypeChange, g, onGChange, guidelines, onDelete, isRound} = props;

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const handleTypeChange = event => {
    onTypeChange(event.target.value);
  }
  const handleGChange = event => {
    setGStr(event.target.value);
    onGChange(parseFloat(event.target.value));
  }

  const output = sentence(type, g, guidelines, isRound);
  const guidelinesTable = <GuidelinesTable guidelines={guidelines} type={type} g={g} />;

  return (
    <Badge badgeContent={"#" + index} anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }} color="primary" >
      <Card sx={{width: {xs: '100%', sm: 'auto'}}}>
        <Grid container >
          <Grid item xs={12} sm="auto" md={6} lg="auto" >
            <CardContent >
              <Stack spacing={2} >
                <TextField select hiddenLabel value={type} onChange={handleTypeChange} SelectProps={{native: isMobile}} >
                  {DRUG_TYPES.map(drug => (
                    isMobile
                    ? <option key={drug} value={drug}>{drug.replace("_", " ")}</option>
                    : <MenuItem key={drug} value={drug}>{drug.replace("_", " ")}</MenuItem>
                  ))};
                </TextField>
                <TextField
                  hiddenLabel
                  inputProps={{ inputMode: 'decimal' }}
                  value={gStr}
                  onChange={handleGChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">grammes</InputAdornment>
                  }}
                  error={isError(gStr)}
                  helperText={isError(gStr) && "Invalid number"}
                />
                <CardBasic title="Starting point">
                  <CardContent sx={{mt: -4, mb: -1}}>
                    <Stack direction="row">
                      <Typography variant="h5">{output?.text}</Typography>
                      {!isNaN(output?.months) && 
                      <BasicPopover>
                        <Calculation output={output} isRound={isRound} />
                      </BasicPopover>
                      }
                    </Stack>
                  </CardContent>
                </CardBasic>
                <Accordion disableGutters sx={{ display: { xs: 'block', sm: 'none' } }} >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography>Guidelines</Typography>
                  </AccordionSummary>
                  <AccordionDetails>{guidelinesTable}</AccordionDetails>
                </Accordion>
                <Button onClick={onDelete} sx={{maxWidth: 'fit-content'}} startIcon={<DeleteIcon />}>Delete</Button>
              </Stack>
            </CardContent>
          </Grid>
          <Grid item xs sx={{ display: { xs: 'none', sm: 'block' } }} >
            {guidelinesTable}
          </Grid>
        </Grid>
      </Card>
    </Badge>
  );
}

function sum(arr) {
  return arr.reduce((a, b) => a + b, 0);
}

function ratioTest(drugs, guidelines, isRound) {
  const totalG = sum(drugs.map(drug => drug.g));
  const sentences = [];
  for (let i = 0; i < drugs.length; i++) {
    const drug = drugs[i];
    const original = sentence(drug.type, totalG, guidelines, isRound);
    let proportion = drug.g / totalG;
    if (isRound) {
      proportion = roundOff(proportion);
    }
    const months = original.monthsFinal * proportion;
    const monthsFinal = (isRound) ? Math.floor(months) : months;
    const text = months2text(months);
    sentences.push({ drug, original, proportion, monthsFinal, text }); 
  }
  const months = sum(sentences.map(e => e.monthsFinal));
  const text = months2text(months);
  return { totalG, sentences, months, text};
}

function Ratio(props) {
  const { ratio, isRound } = props;
  return (
    <BasicPopover>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Drug</TableCell>
            <TableCell>For total {ratio.totalG}g</TableCell>
            <TableCell>g prop.{isRound && " (2 dp)"}</TableCell>
            <TableCell>Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ratio.sentences.map((e, index) => (
            <TableRow key={index} sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
              <TableCell><Badge badgeContent={"#" + (index + 1)} color="primary" /></TableCell>
              <TableCell>{e.drug.type} ({e.drug.g}g)</TableCell>
              <TableCell>{e.original.text}</TableCell>
              <TableCell>{(isRound) ? e.proportion.toFixed(2) : display(e.proportion)}</TableCell>
              <TableCell>{e.text}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BasicPopover>
  );
}

function conversionTest(drugs, guidelines, isRound) {
  const baseDrugs = [... new Set(drugs.map(drug => drug.type))];
  const output = baseDrugs.map(type => {
    const gs = drugs.map(drug => {
      if (drug.type === type) {
        return { drug, g: drug.g };
      }
      const monthsFinal = sentence(drug.type, drug.g, guidelines, isRound).monthsFinal;
      const g = months2g(monthsFinal, type, guidelines, isRound);
      return { drug, g };
    });
    const totalG = sum(gs.map(e => e.g));
    const result = sentence(type, totalG, guidelines, isRound);
    return { type, gs, totalG, result };
  });
  const averageMo = Math.floor(sum(output.map(base => base.result.monthsFinal))/output.length);
  const average = months2text(averageMo);
  return { output, averageMo, average };
}

function Base(props) {
  const {base, isRound} = props;
  if (!base.result.months) return;
  return (
    <BasicPopover>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Drug</TableCell>
            <TableCell>Equivalent g of {base.type}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {base.gs.map((e, index) => (
            <TableRow key={index} sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
              <TableCell><Badge badgeContent={"#" + (index + 1)} color="primary" /></TableCell>
              <TableCell>{e.drug.type} ({e.drug.g}g)</TableCell>
              <TableCell>{display(e.g)}g</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br />
      <Calculation output={base.result} isRound={isRound} />
    </BasicPopover>
  );
}

function mostSerious(drugs, guidelines) {
  let months = 0;
  let mostSerious = "";
  for (let i = 0; i < drugs.length; i++) {
    const drug = drugs[i];
    const monthsFinal = sentence(drug.type, 200, guidelines).monthsFinal;
    if (monthsFinal > months) {
      months = monthsFinal;
      mostSerious = drug.type;
    }
  }
  return mostSerious;
}

function absurdityTest(drugs, guidelines, isRound) {
  const type = mostSerious(drugs, guidelines);
  const totalG = sum(drugs.map(drug => drug.g));
  const result = sentence(type, totalG, guidelines, isRound);
  return { type, totalG, result };
}

function Absurdity(props) {
  const {absurdity, isRound} = props;
  if (!absurdity.result.months) return;
  return (
    <BasicPopover>
      <Typography>Most serious drug: {absurdity.type}</Typography>
      <Calculation output={absurdity.result} isRound={isRound} />
    </BasicPopover>
  );
}

function individualApproach(drugs, guidelines, isRound) {
  const sentences = drugs.map(drug => sentence(drug.type, drug.g, guidelines, isRound));
  const totalMo = sum(sentences.map(sentence => sentence.monthsFinal));
  const text = months2text(totalMo);
  return { sentences, totalMo, text };
}

// function Individual(props) {
//   const individual = props.individual;
//   return (
//     <CardBasic title="Individual approach" text={individual.text}>
//       {/* <CardContent sx={{my: -2}}>
//         <Typography>
//           {individual.sentences.map(sentence => sentence.monthsFinal).join(' + ')} = {individual.totalMo} months 
//         </Typography>
//       </CardContent> */}
//     </CardBasic>
//   )
// }

function StartingPoint(props) {
  const { drugs, guidelines, onStartChange, isRound } = props;
  const individual = individualApproach(drugs, guidelines, isRound);
  const conversion = conversionTest(drugs, guidelines, isRound);
  const ratio = ratioTest(drugs, guidelines, isRound);
  const absurdity = absurdityTest(drugs, guidelines, isRound);

  const [isOverride, setOverride] = useState(false);
  const [yrStr, setYrStr] = useState("0");
  const [moStr, setMoStr] = useState("0");

  const yr = parseInt(yrStr);
  const mo = parseInt(moStr);

  const reset = () => {
    const start = conversion.averageMo;
    if (isNaN(start)) {
      setYrStr("0");
      setMoStr("0");
      return;
    }
    setYrStr(Math.floor(start / 12));
    setMoStr(Math.floor(start % 12));
  }

  const handleOverrideChange = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      reset();
    }
    setOverride(checked);
  };
  const handleYrChange = (event) => {
    setYrStr(event.target.value);
  };
  const handleMoChange = (event) => {
    setMoStr(event.target.value);
  };

  useEffect(() => {
    reset();
    setOverride(false);
  }, [conversion.averageMo])

  useEffect(() => {
    const start = isOverride ? yr * 12 + mo : conversion.averageMo;
    onStartChange(start);
  },[isOverride, yrStr, moStr]);

  const startText = isOverride ? months2text(yr * 12 + mo) : conversion.average;
  let title = "Starting point";
  if (isOverride) {
    title += " (Overriden)";
  } else if (drugs.length > 1) {
    title += " (Conversion test average)";
  }
  
  return (
    <CardBasic title={title} text={startText} >
      <CardContent sx={{mt: -3, mb: -1}}>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <FormControlLabel control={<Switch checked={isOverride} onChange={handleOverrideChange} />} label="Override" />
          </Grid>
          
          {isOverride && <Grid item xs>
            <Stack direction="row" spacing={1} >
              <TextField
                size="small"
                hiddenLabel
                value={yrStr}
                onChange={handleYrChange}
                inputProps={{ inputMode: 'numeric' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">yr</InputAdornment>
                }}
                error={isError(yrStr)}
                helperText={isError(yrStr) && "Invalid number"}
                sx={{maxWidth: 100}}
              />
              <TextField
                size="small"
                hiddenLabel
                value={moStr}
                onChange={handleMoChange}
                inputProps={{ inputMode: 'numeric', maxLength: 2 }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">mo</InputAdornment>
                }}
                error={isError(moStr)}
                helperText={isError(moStr) && "Invalid number"}
                sx={{maxWidth: 100}}
              />
            </Stack>
          </Grid>}
        </Grid>
      </CardContent>
      {drugs.length > 1 && <TableContainer><Table size='small'>
        <TableBody>
          <TableRow>
            <TableCell>Individual approach</TableCell>
            <TableCell>{individual.sentences.map(sentence => display(sentence.monthsFinal)).join(' + ')} = {display(individual.totalMo)} months</TableCell>
            <TableCell>{individual.text}</TableCell>
          </TableRow>
          {conversion.output.map((base, id) => (
            <TableRow key={id}>
              {/* {id===0 && <TableCell rowSpan={conversion.output.length + 2}>Combined<br/>approach</TableCell>} */}
              {id===0 && <TableCell rowSpan={conversion.output.length}>Conversion test</TableCell>}
              <TableCell>{base.type} as base</TableCell>
              <TableCell>
                <Stack direction="row">{base.result.text}<Base base={base} isRound={isRound} /></Stack>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2}>Ratio test</TableCell>
            <TableCell>
              <Stack direction="row">{ratio.text}<Ratio ratio={ratio} isRound={isRound} /></Stack>
            </TableCell>
          </TableRow>
          <TableRow sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
            <TableCell colSpan={2}>Absurdity test</TableCell>
            <TableCell>
              <Stack direction="row">{absurdity.result.text}<Absurdity absurdity={absurdity} isRound={isRound} /></Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table></TableContainer>}
    </CardBasic>
  )
}

function enhance(drugs) {
  const g = sum(drugs.filter(drug => drug.type === 'Heroin/Cocaine' || drug.type ==='Ice').map(drug => drug.g));
  let row = 4;
  let enhancement = 24;
  if (isNaN(g)) {
    row = 0;
    enhancement = 0;
  } else if (g <= 250) {
    row = 1;
    enhancement = 6;
  } else if (g <= 500) {
    row = 2;
    enhancement = y(250, 500, 6, 12, g).result;
  } else if (g <= 1000) {
    row = 3;
    enhancement = y(500, 1000, 12, 24, g).result;
  }
  return { g, row, enhancement };
}

function EnhancementGuidelines(props) {
  const drugs = props.drugs;
  const enhancement = enhance(drugs);
  const rowStyle = {['&:nth-of-type(' + enhancement.row + ')']: { backgroundColor: HIGHLIGHT, color: 'white' }};
  return (
    <Stack spacing={1}>
      <Typography>Total g for Heroin/Cocaine/Ice = {enhancement.g}g</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Grammes</TableCell>
            <TableCell>Enhancement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={rowStyle}>
            <TableCell sx={{color: 'inherit'}}>Up to 250g</TableCell>
            <TableCell sx={{color: 'inherit'}}>6 months</TableCell>
          </TableRow>
          <TableRow sx={rowStyle}>
            <TableCell sx={{color: 'inherit'}}>250g - 500g</TableCell>
            <TableCell sx={{color: 'inherit'}}>6 months - 1 year</TableCell>
          </TableRow>
          <TableRow sx={rowStyle}>
            <TableCell sx={{color: 'inherit'}}>500g - 1000g</TableCell>
            <TableCell sx={{color: 'inherit'}}>1 year - 2 years</TableCell>
          </TableRow>
          <TableRow sx={rowStyle}>
            <TableCell sx={{color: 'inherit'}}>Above 1000g</TableCell>
            <TableCell sx={{color: 'inherit'}}>2 years</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  )
}

function FinalSentence(props) {
  const [isAgg, setAgg] = useState(false);
  const [isMit, setMit] = useState(false);
  const [isOneThird, setOneThird] = useState(true);
  const [discount, setDiscount] = useState('0');

  const handleAggChange = (event) => {
    setAgg(event.target.checked);
  }
  const handleMitChange = (event) => {
    setMit(event.target.checked);
  }
  const handleOneThirdChange = (event) => {
    setOneThird(event.target.value === "true");
  }
  const handleDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const { drugs, start } = props;
  const enhancement = isAgg ? enhance(drugs).enhancement : 0;
  const afterAgg = Math.floor(start + enhancement);
  let discountFraction = 0;
  if (isMit) {
    discountFraction = isOneThird ? 1/3 : parseFloat(discount)/100;
  }
  const reduction = afterAgg * discountFraction;
  const finalSentence = Math.floor(afterAgg - reduction);

  return (
    <Stack spacing={2} >
      <Card>
        <Table >
          <TableBody>
            <TableRow>
              <TableCell>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={3.5} md={12} lg={3} >
                    <FormControlLabel control={<Switch checked={isAgg} onChange={handleAggChange} />} label="Aggravating factor" />
                  </Grid>
                  <Grid item xs="auto" >
                    For Heroin / Cocaine / Ice:<br/>(1) International element;<br/>(2) Previous conviction;<br/>(3) Mastermind; or<br/>(4) Engaged young person
                  </Grid>
                  {isAgg &&
                  <Grid item xs >
                    <Typography color="error.main">+ {plural(enhancement, "month")}</Typography>
                    <Stack direction="row" >
                      <Typography variant="h5">{months2text(afterAgg)}</Typography>
                      <BasicPopover><EnhancementGuidelines drugs={drugs} /></BasicPopover>
                    </Stack>
                  </Grid>}
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
              <TableCell>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={3.5} md={12} lg={3} >
                    <FormControlLabel control={<Switch checked={isMit} onChange={handleMitChange} />} label="Mitigating factor" />
                  </Grid>
                  <Grid item xs="auto" >
                    <RadioGroup value={isOneThird} onChange={handleOneThirdChange} >
                      <FormControlLabel value={true} control={<Radio size="small" disabled={!isMit} />} label="1/3 discount" />
                      <Stack direction="row">
                        <FormControlLabel value={false} control={<Radio size="small" disabled={!isMit} />} label="Other" />
                        {!isOneThird && <TextField
                          hiddenLabel
                          size="small"
                          sx={{width: 80}}
                          value={discount}
                          onChange={handleDiscountChange}
                          inputProps={{ inputMode: 'decimal' }}
                          disabled={!isMit}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                          error={isError(discount)}
                          helperText={isError(discount) && "Invalid number"}
                        />}
                      </Stack>
                    </RadioGroup>
                  </Grid>
                  {isMit && <Grid item xs >
                    <Typography color="success.main">− {plural(roundOff(reduction), "month")}</Typography>
                    <Typography variant="h5">{months2text(finalSentence)}</Typography>
                  </Grid>}
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <CardBasic title="Sentence" text={months2text(finalSentence)} dark  > 
        <CardContent sx={{mt: -2}}>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.75)">After 1/3 remission: {months2text(finalSentence * 2/3)}</Typography>
        </CardContent>
      </CardBasic>
    </Stack>
  );
}

function Calculator(props) {
  const {guidelines, isRound} = props;
  const defaultDrug = {type: 'Ice', g: 1};
  const [drugs, setDrugs] = useState([defaultDrug]);
  const [start, setStart] = useState(0);
  
  const handleChange = (id, update) => {
    setDrugs(prev => (
      prev.map((drug, drugId) => {
        if (drugId === id) {
          return { ...drug, ...update};
        }
        return drug;
      }
    )));
  }

  const handleStartChange = (value) => {
    setStart(value);
  }

  const addDrug = () => {
    setDrugs([...drugs, defaultDrug]);
  }

  const removeDrug = (id) => {
    setDrugs(drugs.filter((_, i) => i !== id));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={5.5} >
        <Stack spacing={2}>
          {drugs.map((drug, id) => (
            <Drug
              key={id}
              index={id + 1}
              guidelines={guidelines}
              type={drug.type}
              onTypeChange={(type) => handleChange(id, {type})}
              g={drug.g}
              onGChange={(g) => handleChange(id, {g})}
              onDelete={() => removeDrug(id)}
              isRound={isRound} 
            />
          ))}
          <Button onClick={addDrug} sx={{width: 'fit-content'}} startIcon={<AddIcon />}>Add drug</Button>
        </Stack>
      </Grid>
      <Grid item xs >
        <Stack spacing={2} >
          <StartingPoint drugs={drugs} guidelines={guidelines} onStartChange={handleStartChange} isRound={isRound} />
          <FinalSentence drugs={drugs} start={start} isRound={isRound} />
        </Stack>
      </Grid>
    </Grid>
  );
}

function chartData(guidelines, start, end) {
  const datasets = DRUG_TYPES.slice(start, end).map((type, id) => {
    const filtered = filteredGuidelines(guidelines, type);
    const array = filtered.map(drug => {
      const x = drug.minG; //|| 0.1
      const y = drug.minYr;
      return { x, y };
    });
    const last = filtered[filtered.length - 1];
    array.push({
      x: last.maxG,
      y: last.maxYr
    });
    return {
      label: type,
      data: array,
      borderColor: COLORS[id + start],
      backgroundColor: COLORS[id + start],
      showLine: true
    }
  });
  return { datasets };
}

function GuidelinesPane(props) {
  const [isLog, setLog] = useState(true);
  const guidelines = props.guidelines;
  const data = chartData(guidelines, 0, 4);
  // const cannabisData = chartData(guidelines, 3, 4);
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const options = {
    scales: {
      x: {
        type: isLog ? 'logarithmic' : 'linear',
        title: {
          display: true,
          text: 'grammes'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'years'
        }
      }
    },
    aspectRatio: isMobile ? 1 : 1.5
  };

  const handleChange = (event) => {
    setLog(event.target.checked);
  }

  return ( //sx={{maxWidth: 'md'}}
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={7} >
        <Card > 
          <CardContent >
            <Stack alignItems="center" >
              <Scatter data={data} options={options} />
              <FormControlLabel control={<Switch checked={isLog} onChange={handleChange} />} label="Log scale" />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid container spacing={2} item xs >
        {DRUG_TYPES.map((type, index) => (
        <Grid item>
          <Card>
            <CardContent sx={{mb: -1}}>
              <Typography variant="body2" color={COLORS[index]}>{type}</Typography>
            </CardContent>
            <GuidelinesTable guidelines={guidelines} type={type} g={16000} />
          </Card>
        </Grid> 
        ))}
      </Grid>
    </Grid>
  );
}

function App() {
  const [guidelines, setGuidelines] = useState();
  const [isOpen, setOpen] = useState(false);
  const [tabId, setTabId] = useState(0);
  const [isRound, setRound] = useState(true);

  useEffect(() => {
    fetch('guidelines.csv')
      .then(res => res.text())
      .then(text => {
        csv().fromString(text).then(data => {
          setGuidelines(values2float(data));
        });
      });
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  if (!guidelines) return;

  const handleTabChange = (_event, newValue) => {
    setTabId(newValue);
  };

  const handleSwitchChange = (event) => {
    setRound(event.target.checked);
  }

  const title = <Typography fontFamily="Product Sans, Roboto" variant="h6" color="text.secondary" >TDD Sentencing</Typography>;
  const roundSwitch =
  <>
    <FormControlLabel control={<Switch checked={isRound} onChange={handleSwitchChange} />} label="Round off" />
    <BasicPopover info>
      <Typography>Round off each sub-equation to nearest 2 d.p., and round down sentence to nearest month within calculations.</Typography>
      <Typography>(Sentence for each step is always rounded down to nearest month.)</Typography>
    </BasicPopover>
  </>;

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="transparent" variant="outlined" sx={{borderWidth: '0px 0px 1px 0px', bgcolor: 'white'}} >
          <Toolbar  >
            <IconButton size="large" edge="start" sx={{ mr: 1, display: { xs: 'inline-flex', sm: 'none' } }} onClick={() => setOpen(true)}><MenuIcon /></IconButton>
            {title}
            <Divider orientation="vertical" variant="middle" flexItem sx={{mx: 3, display: { xs: 'none', sm: 'block' }}} />
            {/* <Typography fontFamily="Product Sans, Roboto" variant="h6" color="text.secondary" >Calculator</Typography> */}
            <Box sx={{display: { xs: 'none', sm: 'inline-flex'}, flexGrow: 1}}>
              <Tabs value={tabId} onChange={handleTabChange}>
                <Tab label="Calculator" sx={{height: 64}} />
                <Tab label="Guidelines" sx={{height: 64}} />
              </Tabs>
            </Box>
            {!isMobile && !tabId && roundSwitch}
          </Toolbar>
      </AppBar>
      <Drawer open={isOpen} onClose={() => setOpen(false)}>
        <Toolbar>{title}</Toolbar>
        <Box sx={{width: 250}} onClick={() => setOpen(false)} onKeyDown={() => setOpen(false)}>
          <List>
            <ListItem key="Calculator" disablePadding>
              <ListItemButton onClick={() => setTabId(0)} selected={tabId === 0}>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Calculator" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Guidelines" disablePadding>
              <ListItemButton onClick={() => setTabId(1)} selected={tabId === 1}>
                <ListItemIcon><GavelIcon /></ListItemIcon>
                <ListItemText primary="Guidelines" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <List><ListItem>{roundSwitch}</ListItem></List>
      </Drawer>
      <Container sx={{py: 3}} >
        {tabId
        ? <GuidelinesPane guidelines={guidelines} />
        : <Calculator guidelines={guidelines} isRound={isRound} />}
        <Typography variant="body2" color="text.secondary" align="right" sx={{mt: 5}}>© 2022 JF</Typography>
      </Container>
    </ThemeProvider>  
  );
}

export default App;
